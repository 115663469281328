import React from "react";
import { useForm } from "react-hook-form";
import { Product as ProductType } from "../../types";
import Order from "./components/Order";

const Checkout = ({ cart }: { cart: ProductType[] }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => console.log(data);

  const deliveryType = watch("deliveryType");
  const deliveryTimeType = watch("deliveryTimeType");

  return (
    <section className="pt-0">
      <div className="padding-50px-top" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7 col-md-6 padding-70px-right lg-padding-40px-right md-padding-15px-right">
              <div className="row">
                <div className="col-12">
                  <span className="alt-font text-large text-extra-dark-gray margin-20px-bottom d-inline-block font-weight-500">
                    Ваши контакты
                  </span>
                </div>
                <div className="col-md-6 margin-10px-bottom">
                  <label className="margin-15px-bottom">
                    Имя <span className="text-radical-red">*</span>
                  </label>
                  <input
                    type="text"
                    className="small-input mb-0"
                    {...register("name", { required: true })}
                  />
                  {errors.name?.type === "required" && (
                    <div className="invalid-feedback d-block">
                      Введите ваше имя.
                    </div>
                  )}
                </div>
                <div className="col-md-6 margin-10px-bottom">
                  <label className="margin-15px-bottom">
                    Телефон <span className="text-radical-red">*</span>
                  </label>
                  <input
                    type="text"
                    className="small-input mb-0"
                    {...register("phone", { required: true })}
                  />
                  {errors.phone?.type === "required" && (
                    <div className="invalid-feedback d-block">
                      Введите ваш номер телефона.
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <span className="alt-font text-large text-extra-dark-gray margin-20px-bottom d-inline-block font-weight-500">
                    Доставка и оплата
                  </span>
                </div>

                <div className="col-12 margin-10px-bottom">
                  <label className="margin-15px-bottom" htmlFor="deliveryType">
                    Способ доставки: <span className="text-radical-red">*</span>
                  </label>
                  <select
                    id="deliveryType"
                    className="small-input"
                    {...register("deliveryType")}
                  >
                    <option value="asap">Доставка курьером</option>
                    <option value="selfDelivery">Забрать самому</option>
                  </select>
                </div>

                {/*{() => console.log(deliveryType)}*/}
                {/*<button onClick={() => console.log(deliveryType)}>
                  D type
                </button>*/}

                {deliveryType === "selfDelivery" && (
                  <div className="col-12 margin-10px-bottom">
                    {/*<label
                      className="margin-15px-bottom"
                      htmlFor="deliveryType"
                    >
                      Способ доставки:{" "}
                      <span className="text-radical-red">*</span>
                    </label>*/}
                    <select
                      id="branch"
                      className="small-input"
                      {...register("branch")}
                    >
                      <option value={1}>
                        Молодежная, 7. График работы: 10:30 – 23:59
                      </option>
                      <option value={2}>
                        Кулика, 10. График работы: 10:30 – 23:59
                      </option>
                    </select>
                  </div>
                )}

                <div className="col-12 margin-10px-bottom">
                  <label
                    className="margin-15px-bottom"
                    htmlFor="deliveryTimeType"
                  >
                    Время{deliveryType !== "selfDelivery" && " доставки"}:{" "}
                    <span className="text-radical-red">*</span>
                  </label>
                  <select
                    id="deliveryTimeType"
                    defaultValue="asap"
                    className="small-input"
                    {...register("deliveryTimeType")}
                  >
                    <option value="asap">Как можно скорее</option>
                    <option value="time">Конкретное время</option>
                  </select>
                </div>

                {deliveryTimeType === "time" && (
                  <div className="col-12 margin-10px-bottom">
                    {/*<label className="margin-15px-bottom" htmlFor="deliveryTime">
                    Время{deliveryType !== "selfDelivery" && " доставки"}:{" "}
                    <span className="text-radical-red">*</span>
                  </label>*/}
                    <input
                      type="datetime-local"
                      className="small-input"
                      min={""}
                      {...register("deliveryTime")}
                    />
                  </div>
                )}

                {deliveryType !== "selfDelivery" && (
                  <>
                    <div className="col-8 margin-10px-bottom">
                      <label className="margin-15px-bottom">
                        Адрес доставки{" "}
                        <span className="text-radical-red">*</span>
                      </label>
                      <input
                        className="small-input"
                        type="text"
                        {...register("address")}
                      />
                    </div>
                    <div className="col-4 margin-10px-bottom">
                      <label className="margin-15px-bottom">
                        Номер дома <span className="text-radical-red">*</span>
                      </label>
                      <input
                        className="small-input"
                        type="text"
                        {...register("houseNumber")}
                      />
                    </div>

                    <div className="col-4 margin-10px-bottom">
                      <label className="margin-15px-bottom">
                        Номер квартиры
                      </label>
                      <input
                        className="small-input"
                        type="text"
                        {...register("apartmentNumber")}
                      />
                    </div>
                    <div className="col-4 margin-10px-bottom">
                      <label className="margin-15px-bottom">Этаж</label>
                      <input
                        className="small-input"
                        type="text"
                        {...register("floor")}
                      />
                    </div>
                    <div className="col-4 margin-10px-bottom">
                      <label className="margin-15px-bottom">Домофон</label>
                      <input
                        className="small-input"
                        type="text"
                        {...register("homophone")}
                      />
                    </div>

                    <div className="col-12 margin-10px-bottom">
                      <label className="margin-15px-bottom" htmlFor="payment">
                        Способ оплаты{" "}
                        <span className="text-radical-red">*</span>
                      </label>
                      <select
                        id="payment"
                        className="small-input"
                        {...register("payment")}
                      >
                        <option value="cash">Наличными</option>
                        <option value="terminal">Терминал</option>
                      </select>
                    </div>
                  </>
                )}

                <div className="col-12 margin-15px-top">
                  <label className="margin-15px-bottom">
                    Коментарий к заказу
                  </label>
                  <textarea
                    rows={5}
                    cols={5}
                    className="medium-input"
                    placeholder="Заметки о вашем заказе, например, особые указания по доставке."
                    {...register("comment")}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5 col-md-6">
              <Order cart={cart} />
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default Checkout;
