import React from "react";
import { useHistory } from "react-router-dom";
import { Product as ProductType } from "../../types";

const Cart = ({
  cart,
  removeProductFromCart,
}: {
  cart: ProductType[];
  removeProductFromCart: (id: number) => void;
}) => {
  const history = useHistory();

  return (
    <>
      {/*<section className="wow animate__fadeIn bg-light-gray padding-25px-tb page-title-small">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-xl-8 col-lg-6">
              <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom text-center text-lg-start">
                Shopping cart
              </h1>
            </div>
            <div className="col-12 col-xl-4 col-lg-6 breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-10px-top">
              <ul className="xs-text-center">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>
                  <a href="shop-wide.html">Shop</a>
                </li>
                <li>Shopping cart</li>
              </ul>
            </div>
          </div>
        </div>
      </section>*/}
      <section className="padding-50px-top wow animate__fadeIn">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 padding-70px-right lg-padding-30px-right md-padding-15px-right">
              <div className="row align-items-center">
                <div className="col-12">
                  <table className="table cart-products margin-60px-bottom md-margin-40px-bottom sm-no-margin-bottom">
                    <thead>
                      <tr>
                        <th scope="col" className="alt-font" />
                        <th scope="col" className="alt-font" />
                        <th scope="col" className="alt-font">
                          Продукт
                        </th>
                        <th scope="col" className="alt-font">
                          Цена
                        </th>
                        <th scope="col" className="alt-font">
                          Количество
                        </th>
                        <th scope="col" className="alt-font">
                          Итого
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((product, i) => (
                        <tr key={i}>
                          <td className="product-remove">
                            <a href="#" className="btn-default text-large">
                              &times;
                            </a>
                          </td>
                          <td className="product-thumbnail">
                            <a href="/">
                              <img
                                className="cart-product-image"
                                src="https://via.placeholder.com/600x600"
                                alt=""
                                style={{ height: "80px", width: "80px" }}
                              />
                            </a>
                          </td>
                          <td className="product-name">
                            <a href="/">{product.name}</a>
                            {/*<span className="variation"> Size: L</span>*/}
                          </td>
                          <td className="product-price" data-title="Price">
                            {product.price} грн.
                          </td>
                          <td
                            className="product-quantity"
                            data-title="Quantity"
                          >
                            <div className="quantity">
                              <label className="screen-reader-text">
                                Количество
                              </label>
                              <input
                                type="button"
                                value="-"
                                className="qty-minus qty-btn"
                                data-quantity="minus"
                                data-field="quantity"
                                onClick={() => console.log("click")}
                              />
                              <input
                                type="number"
                                name="quantity"
                                value="1"
                                className="input-text qty-text"
                                readOnly
                              />
                              <input
                                type="button"
                                value="+"
                                className="qty-plus qty-btn"
                                data-quantity="plus"
                                data-field="quantity"
                                onClick={() => console.log("click")}
                              />
                            </div>
                          </td>
                          <td className="product-subtotal" data-title="Total">
                            {product.price}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/*<div className="col-md-6 md-margin-50px-bottom sm-margin-20px-bottom">
                  <div className="coupon-code-panel">
                    <input type="text" placeholder="Coupon code" />
                    <a href="#" className="btn apply-coupon-btn text-uppercase">
                      Apply
                    </a>
                  </div>
                </div>*/}
                {/*<div className="col-md-6 text-center text-md-end md-margin-50px-bottom btn-dual">
                  <a
                    href="#"
                    className="btn btn-fancy btn-small btn-transparent-light-gray"
                  >
                    Empty cart
                  </a>
                  <a
                    href="#"
                    className="btn btn-fancy btn-small btn-transparent-light-gray me-0"
                  >
                    Update cart
                  </a>
                </div>*/}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="bg-light-gray padding-50px-all lg-padding-30px-tb lg-padding-20px-lr md-padding-20px-tb">
                {/*<span className="alt-font text-large text-extra-dark-gray margin-15px-bottom d-inline-block font-weight-500">
                  Cart totals
                </span>*/}
                <table className="w-100 total-price-table">
                  <tbody>
                    {/*<tr>
                      <th className="w-50 font-weight-500 text-extra-dark-gray">
                        Subtotal
                      </th>
                      <td className="text-extra-dark-gray">$405.00</td>
                    </tr>*/}
                    {/*<tr className="shipping">
                      <th className="font-weight-500 text-extra-dark-gray">
                        Shipping
                      </th>
                      <td data-title="Shipping">
                        <ul className="float-lg-start float-end text-start line-height-36px">
                          <li className="d-flex align-items-center md-margin-15px-bottom">
                            <input
                              id="free_shipping"
                              type="radio"
                              name="shipping-option"
                              className="d-block w-auto margin-10px-right mb-0"
                              checked
                            />
                            <label
                              className="md-line-height-18px"
                              htmlFor="free_shipping"
                            >
                              Free shipping
                            </label>
                          </li>
                          <li className="d-flex align-items-center md-margin-15px-bottom">
                            <input
                              id="flat"
                              type="radio"
                              name="shipping-option"
                              className="d-block w-auto margin-10px-right mb-0"
                            />
                            <label
                              className="md-line-height-18px"
                              htmlFor="flat"
                            >
                              Flat: $12.00
                            </label>
                          </li>
                          <li className="d-flex align-items-center">
                            <input
                              id="local_pickup"
                              type="radio"
                              name="shipping-option"
                              className="d-block w-auto margin-10px-right mb-0"
                            />
                            <label
                              className="md-line-height-18px"
                              htmlFor="local_pickup"
                            >
                              Local pickup
                            </label>
                          </li>
                        </ul>
                      </td>
                    </tr>*/}

                    <tr className="total-amount">
                      <th className="font-weight-500 text-extra-dark-gray">
                        Сумма
                      </th>
                      <td data-title="Total">
                        <h6 className="d-block font-weight-500 mb-0 text-extra-dark-gray">
                          405.00 грн.
                        </h6>
                        {/*<span className="text-small text-extra-medium-gray">
                          (Includes $19.29 tax)
                        </span>*/}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <a
                    type="button"
                    className="btn btn-primary btn-large font-weight-700 d-block btn-fancy margin-15px-top"
                    onClick={() => history.push("/checkout")}
                  >
                    Оформить
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
