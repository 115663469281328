import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Products from "./containers/Products";
import Product from "./containers/Product";
import Cart from "./containers/Cart";
import Checkout from "./containers/Checkout";

// import HeaderOld from "./components/Header";
import Header from "./components/NewHeader";
import Footer from "./components/Footer";

import useCart from "./hooks/useCart";

const App = () => {
  const { cart, addProductToCart, removeProductFromCart } = useCart();

  return (
    <Router>
      {/*<HeaderOld cart={cart} removeProductFromCart={removeProductFromCart} />*/}
      <Header cart={cart} removeProductFromCart={removeProductFromCart} />

      <Switch>
        <Route path="/" exact>
          <Products addProductToCart={addProductToCart} />
        </Route>
        <Route path="/products/:id" exact>
          <Product addProductToCart={addProductToCart} />
        </Route>
        <Route path="/cart" exact>
          <Cart cart={cart} removeProductFromCart={removeProductFromCart} />
        </Route>
        <Route path="/checkout" exact>
          <Checkout cart={cart} />
        </Route>
      </Switch>

      <Footer />
    </Router>
  );
};

export default App;
