import React from "react";
import { useHistory } from "react-router-dom";
import { Popover } from "@mui/material";
import CartList from "../Header/components/CartList";
import { Product as ProductType } from "../../types";

const Header = ({
  cart,
  removeProductFromCart,
}: {
  cart: ProductType[];
  removeProductFromCart: (id: number) => void;
}) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header>
      <nav className="navbar top-space navbar-expand-lg navbar-boxed navbar-dark bg-primary header-dark header-reverse-scroll">
        <div className="container-fluid nav-header-container">
          <div className="col-auto d-lg-none d-md-none d-sm-none d-none">
            <button
              className="navbar-toggler float-end"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-line" />
              <span className="navbar-toggler-line" />
              <span className="navbar-toggler-line" />
              <span className="navbar-toggler-line" />
            </button>
          </div>
          <div className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
            <a
              type="button"
              className="text-large font-weight-700 text-white padding-10px-left"
              onClick={() => history.push("/")}
            >
              LikeFish
            </a>
          </div>
          <div className="col-auto menu-order px-lg-0">
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav alt-font">
                <li className="nav-item dropdown megamenu">
                  <a
                    className="nav-link font-weight-700"
                    onClick={() => history.push("/")}
                  >
                    Главная
                  </a>
                  <i
                    className="fa fa-angle-down dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-hidden="true"
                  />
                </li>
                <li className="nav-item dropdown megamenu">
                  <a
                    className="nav-link font-weight-700"
                    onClick={() => history.push("/products/1")}
                  >
                    Продукт
                  </a>
                  <i
                    className="fa fa-angle-down dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-hidden="true"
                  />
                </li>
                <li className="nav-item dropdown simple-dropdown">
                  <a
                    className="nav-link font-weight-700"
                    onClick={() => history.push("/cart")}
                  >
                    Корзина
                  </a>
                  <i
                    className="fa fa-angle-down dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-hidden="true"
                  />
                </li>
                <li className="nav-item dropdown simple-dropdown">
                  <a
                    className="nav-link font-weight-700"
                    onClick={() => history.push("/checkout")}
                  >
                    Чекаут
                  </a>
                  <i
                    className="fa fa-angle-down dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-hidden="true"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-auto text-end hidden-xs pe-0 font-size-0">
            <div className="header-cart-icon dropdown">
              <a
                className="font-weight-700"
                onClick={(event) => handleClick(event)}
              >
                <i className="feather icon-feather-shopping-bag" />
                <span className="cart-count alt-font bg-dark-red text-white">
                  {cart.length}
                </span>
              </a>
              {/*<ul className="dropdown-menu cart-item-list">
                <li className="cart-item align-items-center">
                  <a href="#" className="alt-font close">
                    ×
                  </a>
                  <div className="product-image">
                    <a href="/">
                      <img
                        src="https://via.placeholder.com/150x191"
                        className="cart-thumb"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-detail alt-font">
                    <a href="/">Top</a>
                    <span className="item-ammount">$100.00</span>
                  </div>
                </li>
                <li className="cart-item align-items-center">
                  <a href="#" className="alt-font close">
                    ×
                  </a>
                  <div className="product-image">
                    <a href="/">
                      <img
                        src="https://via.placeholder.com/150x191"
                        className="cart-thumb"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-detail alt-font">
                    <a href="/">Rossi</a>
                    <span className="item-ammount">$99.99</span>
                  </div>
                </li>
                <li className="cart-item cart-total">
                  <div className="alt-font margin-15px-bottom">
                    <span className="w-50 d-inline-block text-medium text-uppercase">
                      Subtotal:
                    </span>
                    <span className="w-50 d-inline-block text-end text-medium font-weight-500">
                      $199.99
                    </span>
                  </div>
                  <a href="/" className="btn btn-small btn-dark-gray">
                    view cart
                  </a>
                  <a href="/" className="btn btn-small btn-fast-blue">
                    checkout
                  </a>
                </li>
              </ul>*/}
            </div>
          </div>
        </div>
      </nav>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <CartList
          products={cart}
          removeProductFromCart={removeProductFromCart}
          handleClose={handleClose}
        />
      </Popover>
    </header>
  );
};

export default Header;
