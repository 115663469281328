import React from "react";
import { Product as ProductType } from "../../../../types";

const Order = ({ cart }: { cart: ProductType[] }) => {
  return (
    <div className="bg-light-gray padding-45px-all lg-padding-30px-all sm-padding-20px-all">
      <span className="alt-font text-large text-extra-dark-gray margin-25px-bottom d-inline-block font-weight-700">
        Ваш заказ
      </span>
      <table className="total-price-table checkout-total-price-table">
        <thead className="border-bottom border-color-medium-gray text-extra-dark-gray">
          <tr>
            <th className="product-name font-weight-500 w-60">Продукт</th>
            <th className="product-total pe-0 font-weight-500">Итог</th>
          </tr>
        </thead>
        <tbody>
          {cart.map((product) => (
            <tr>
              <td>{product.name} × 1</td>
              <td>{product.price} грн.</td>
            </tr>
          ))}
          <tr className="total-amount">
            <th className="font-weight-700 text-extra-dark-gray">Итого</th>
            <td>
              <h6 className="d-block font-weight-500 mb-0 text-extra-dark-gray">
                405.00 грн.
              </h6>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        type="submit"
        className="btn btn-primary btn-extra-large w-100 margin-15px-top font-weight-700"
      >
        Оформить
      </button>
    </div>
  );
};

export default Order;
