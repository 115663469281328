import React from "react";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();
  return (
    <footer className="footer-dark bg-slate-blue">
      <div className="footer-bottom padding-35px-tb border-top border-width-1px border-color-white-transparent">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-4 sm-margin-15px-bottom">
              <ul className="footer-horizontal-link d-flex flex-column flex-sm-row justify-content-center justify-content-md-start text-center">
                <li>
                  <a type="button" onClick={() => history.push("/")}>
                    Главная
                  </a>
                </li>
                <li>
                  <a type="button" onClick={() => history.push("/")}>
                    О нас
                  </a>
                </li>
                <li>
                  <a type="button" onClick={() => history.push("/")}>
                    Контакты
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-12 col-md-4 text-center last-paragraph-no-margin">
              <div className="social-icon-style-12">
                <ul className="extra-small-icon light">
                  <li>
                    <a
                      className="instagram"
                      href="https://www.instagram.com"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="facebook"
                      href="https://www.facebook.com"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-md-4 text-center text-md-end last-paragraph-no-margin">
              <p>&copy; 2021 LikeFish. Все права защищены.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
