import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Product as ProductType } from "../../types";

const product: ProductType = {
  id: 6,
  name: "Product #6",
  shortDescription: "8 кусочков, Вес 280 г",
  description: "Нежный ролл со сливочным сыром и свежей сёмгой.",
  price: 100.0,
};

const Product = ({
  addProductToCart,
}: {
  addProductToCart: (product: ProductType) => void;
}) => {
  const history = useHistory();
  const [count, setCount] = useState(1);
  // const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id?: string | undefined }>();

  const { name, price, shortDescription, description } = product;

  return (
    <section
      className="big-section wow animate__fadeIn"
      style={{ padding: "50px 0", minHeight: "calc(100vh - 164px)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 shopping-content d-flex flex-column flex-lg-row align-items-md-center">
            <div className="w-60 md-w-100">
              <div className="product-images-box lightbox-portfolio row">
                <div className="col-12 col-lg-3" />
                <div className="col-12 col-lg-9 position-relative px-lg-0 product-image md-margin-10px-bottom">
                  <img
                    className="w-100"
                    src="https://via.placeholder.com/600x765"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="w-40 md-w-100 product-summary padding-7-rem-left lg-padding-5-rem-left md-no-padding-left">
              <div className="breadcrumb text-small p-0">
                <ul>
                  <li>
                    <a type="button" onClick={() => history.push("/products")}>
                      Главная
                    </a>
                  </li>
                  <li>
                    <a type="button" onClick={() => history.push("/products")}>
                      Продукты
                    </a>
                  </li>
                  <li>Филадельфия Классик</li>
                </ul>
              </div>
              <div className="d-flex align-items-center margin-3-half-rem-top  md-margin-1-half-rem-tb margin-1-half-rem-bottom">
                <div className="flex-grow-1">
                  <div className="text-extra-dark-gray font-weight-500 text-extra-large alt-font margin-5px-bottom">
                    {name}
                  </div>
                  <span className="product-price text-extra-medium">
                    {/*<del>480.00 грн.</del>*/}
                    <strong>{price} грн.</strong>
                  </span>
                </div>
                <div className="text-end line-height-30px">
                  <div>
                    <a href="#" className="letter-spacing-3px">
                      <i className="fas fa-star text-very-small text-golden-yellow" />
                      <i className="fas fa-star text-very-small text-golden-yellow" />
                      <i className="fas fa-star text-very-small text-golden-yellow" />
                      <i className="fas fa-star text-very-small text-golden-yellow" />
                      <i className="fas fa-star text-very-small text-golden-yellow" />
                    </a>
                  </div>
                  <span className="text-small">
                    <span className="text-extra-dark-gray">ID: </span>
                    {id}
                  </span>
                </div>
              </div>
              <div className="last-paragraph-no-margin margin-1-rem-bottom">
                <em>{shortDescription}</em>
              </div>
              <div className="margin-4-rem-bottom">
                <p>{description}</p>
              </div>
              <div>
                <div className="quantity margin-15px-right">
                  <label className="screen-reader-text">Количество</label>
                  <input
                    type="button"
                    value="-"
                    className="qty-minus qty-btn"
                    data-quantity="minus"
                    data-field="quantity"
                    onClick={() => setCount(count - 1)}
                  />
                  <input
                    className="input-text qty-text"
                    type="number"
                    name="quantity"
                    value={count}
                  />
                  <input
                    type="button"
                    value="+"
                    className="qty-plus qty-btn"
                    data-quantity="plus"
                    data-field="quantity"
                    onClick={() => setCount(count + 1)}
                  />
                </div>
                <a
                  className="btn btn-primary btn-medium font-weight-700"
                  onClick={() => addProductToCart(product)}
                >
                  В корзину
                </a>
                {/*<div className="margin-25px-top">
                  <a
                    href="#"
                    className="text-uppercase text-extra-small alt-font margin-20px-right font-weight-500 "
                  >
                    <i className="feather icon-feather-heart align-middle margin-5px-right" />
                    В избранное
                  </a>
                </div>*/}
              </div>
              {/*<div className="d-flex alt-font margin-4-rem-top align-items-center">
                <div className="flex-grow-1">
                  <span className="text-uppercase text-extra-small font-weight-500 text-extra-dark-gray d-block">
                    Tags:{" "}
                    <a href="/" className="font-weight-400">
                      Lather bag
                    </a>
                  </span>
                </div>
                <div className="text-end social-icon-style-02 w-50">
                  <ul className="extra-small-icon">
                    <li>
                      <a
                        className="text-extra-dark-gray facebook"
                        href="https://www.facebook.com/"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-extra-dark-gray twitter"
                        href="https://www.twitter.com"
                        target="_blank"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-extra-dark-gray linkedin"
                        href="https://www.linkedin.com"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-extra-dark-gray flickr"
                        href="https://www.pinterest.com/"
                        target="_blank"
                      >
                        <i className="fab fa-pinterest-p" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Product;
