import { useState } from "react";
import { Product as ProductType } from "../types";

const useCart = () => {
  // @ts-ignore-next-line
  const [cart, setCart] = useState<Array<ProductType>>(() => {
    try {
      const cart = window.localStorage.getItem("cart");
      return cart ? JSON.parse(cart) : [];
    } catch (error) {
      console.log(error);
      return [];
    }
  });

  const addProductToCart = (product: ProductType) => {
    /*const product = {
      id: 1,
      name: "Product #1",
      shortDescription: "8 кусочков, Вес 280 г",
      description: "Нежный ролл со сливочным сыром и свежей сёмгой.",
      price: 50,
    };*/

    try {
      setCart([product, ...cart]);
      window.localStorage.setItem("cart", JSON.stringify([product, ...cart]));
    } catch (error) {
      console.log(error);
    }
  };

  const removeProductFromCart = (id: number) => {
    const filteredCart = cart.filter((product) => product.id !== id);

    try {
      setCart(filteredCart);
      window.localStorage.setItem("cart", JSON.stringify(filteredCart));
    } catch (error) {
      console.log(error);
    }
  };

  const emptyCart = () => {
    try {
      setCart([]);
      window.localStorage.setItem("cart", JSON.stringify([]));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    cart,
    addProductToCart,
    removeProductFromCart,
    emptyCart,
  };
};

export default useCart;
