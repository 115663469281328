import React from "react";
import { useHistory } from "react-router-dom";
import { Product as ProductType } from "../../../../types";

const Product = ({
  product,
  addProductToCart,
}: {
  product: ProductType;
  addProductToCart: (product: ProductType) => void;
}) => {
  const history = useHistory();

  return (
    <div className="blog-post border-radius-5px bg-white box-shadow-medium margin-25px-bottom xs-margin-15px-bottom">
      <div
        className="blog-post-image bg-medium-slate-blue"
        style={{ minHeight: 200, height: 200 }}
      >
        <a
          title={product.name}
          onClick={(e) => {
            e.preventDefault();
            history.push(`/products/${product.id}`);
          }}
        >
          <img
            src="./images/img-example-sushi.jpeg"
            style={{ minHeight: "100%", minWidth: "100%" }}
            alt=""
          />
        </a>
      </div>
      <div className="post-details padding-2-rem-lr padding-1-half-rem-tb">
        <a
          className="alt-font font-weight-500 text-extra-medium text-extra-dark-gray margin-15px-bottom d-block"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/products/${product.id}`);
          }}
        >
          {product.name}
        </a>
        {/*<a
          href="/"
          className="alt-font text-small d-inline-block margin-10px-bottom"
        >
          09 January 2020
        </a>*/}
        <p>{product.description}</p>
        <div className="row align-items-center">
          <div className="col-4">
            <p className="alt-font text-medium d-inline-block mb-0">
              <span className="font-weight-700">{product.price} </span>
              грн.
            </p>
          </div>
          <div className="col-8" style={{ textAlign: "right" }}>
            <a
              type="button"
              className="btn btn-medium btn-success font-weight-700 d-table d-lg-inline-block mb-0 md-margin-auto-lr"
              onClick={() => addProductToCart(product)}
            >
              Заказать
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
