import React from "react";

const Aside = () => {
  return (
    <div className="margin-1-rem-top margin-5-rem-bottom xs-margin-35px-bottom">
      <span className="alt-font font-weight-500 text-large text-extra-dark-gray d-block margin-35px-bottom">
        Категории
      </span>
      <ul className="list-style-07 list-unstyled">
        <li>
          <a type="button">Пицца</a>
          <span className="item-qty">10</span>
        </li>
        <li>
          <a type="button">Суши</a>
          <span className="item-qty">05</span>
        </li>
        <li>
          <a type="button">Роллы</a>
          <span className="item-qty">03</span>
        </li>
      </ul>
    </div>
  );
};

export default Aside;
