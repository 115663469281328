import React from "react";
import { useHistory } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Divider, IconButton, Typography } from "@mui/material";

import { Product as ProductType } from "../../../../types";

export default function CartList({
  products,
  removeProductFromCart,
  handleClose,
}: {
  products: ProductType[];
  removeProductFromCart: (id: number) => void;
  handleClose: () => void;
}) {
  let history = useHistory();

  function goTo(to: string) {
    history.push(to);
    handleClose();
  }

  return (
    <>
      <List
        dense
        sx={{ width: "100%", minWidth: 360, bgcolor: "background.paper" }}
      >
        {products.map(({ id, name, price }, i) => {
          return (
            <ListItem
              key={i}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="comments"
                  onClick={() => {
                    removeProductFromCart(id);
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar alt={`Avatar n°`} src={`/img/item.jpg`} />
                </ListItemAvatar>
                <ListItemText
                  primary={name}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        1 x
                      </Typography>
                      {` ${price} грн.`}
                    </React.Fragment>
                  }
                />
                {/*<ListItemText id={labelId} primary={name} />*/}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folder">
        {/*<ListItemButton>
          <ListItemText primary="Trash" />
        </ListItemButton>
        <ListItemButton>
          <ListItemText primary="Buy" />
        </ListItemButton>*/}

        <ListItemButton>
          <ListItemText primary="Общая сумма 250 грн." />
        </ListItemButton>
        <ListItemButton>
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={() => goTo("/cart")}
          >
            Оформить
          </Button>
        </ListItemButton>
      </List>
    </>
  );
}

// export default CartList
