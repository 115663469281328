import React from "react";
import { Product as ProductType } from "../../types";
import Product from "./components/Product";
import Aside from "./components/Aside";

const products: ProductType[] = [
  {
    id: 1,
    name: "Product #1",
    shortDescription: "8 кусочков, Вес 280 г",
    description: "Нежный ролл со сливочным сыром и свежей сёмгой.",
    price: 470.0,
  },
  {
    id: 2,
    name: "Product #2",
    shortDescription: "8 кусочков, Вес 280 г",
    description: "Нежный ролл со сливочным сыром и свежей сёмгой.",
    price: 420.0,
  },
  {
    id: 3,
    name: "Product #3",
    shortDescription: "8 кусочков, Вес 280 г",
    description: "Нежный ролл со сливочным сыром и свежей сёмгой.",
    price: 430.0,
  },
  {
    id: 4,
    name: "Product #4",
    shortDescription: "8 кусочков, Вес 280 г",
    description: "Нежный ролл со сливочным сыром и свежей сёмгой.",
    price: 440.0,
  },
  {
    id: 5,
    name: "Product #5",
    shortDescription: "8 кусочков, Вес 280 г",
    description: "Нежный ролл со сливочным сыром и свежей сёмгой.",
    price: 270.0,
  },
  {
    id: 6,
    name: "Product #6",
    shortDescription: "8 кусочков, Вес 280 г",
    description: "Нежный ролл со сливочным сыром и свежей сёмгой.",
    price: 100.0,
  },
];

const Products = ({
  addProductToCart,
}: {
  addProductToCart: (product: ProductType) => void;
}) => {
  // CSS classes for loader
  // const [loading] = useState(false);

  return (
    <section className="bg-light-gray shopping-left-side-bar padding-50px-top">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10 col-md-8 shopping-content padding-55px-left md-padding-15px-left sm-margin-30px-bottom">
            <div className="row">
              {products.map((product, i) => (
                <div key={i} className="col-sm-12 col-lg-6 col-xxl-4">
                  <Product
                    product={product}
                    addProductToCart={addProductToCart}
                  />
                </div>
              ))}
            </div>
          </div>

          <aside className="col-12 col-lg-2 col-md-4 shopping-sidebar">
            <Aside />
          </aside>
        </div>
      </div>
    </section>
  );
};

export default Products;
